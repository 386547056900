/*=============================================
=            #Rooms            =
=============================================*/

.rooms {

}

	.room {

	}


/*----------  Rooms - List  ----------*/

.rooms--list {

	.room {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 4rem;

		@include media-breakpoint-up(sm) {
			margin-bottom: 5rem;
		}

		@include media-breakpoint-up(lg) {
			flex-wrap: nowrap;
			align-items: center;
			margin-bottom: 7.5rem;
		}
	}

	// Change layout for even items
	.room:nth-child(even) {

		.room__body {

			@include media-breakpoint-up(lg) {
				order: 2;
			}
		}

		.room__img {

			@include media-breakpoint-up(lg) {
				order: 1;
			}
		}

			.room__badge {
				right: auto;
				left: 0;

				&::before {
					border-right: none;
					right: auto;
					left: 0;
					border-left: 70px solid theme-color("primary");
				}
			}
			.room__badge--secondary {

				&::before {
					border-left-color: theme-color("secondary");
				}
			}
	}

		// Room Content
		.room__body {
			order: 2;
			padding: 2rem;

			@include media-breakpoint-up(lg) {
				width: 42%;
				order: 1;
				padding: 4.375rem 2.75rem;
			}
		}
			.room__complexity {
				margin-bottom: 1.875rem;
			}
			.room__title {
				margin-bottom: .75rem;

				@include media-breakpoint-down(md) {
					font-size: 1.5rem;
				}
			}
			.room__meta {
				margin-bottom: 1.5rem;

				@include media-breakpoint-up(lg) {
					margin-bottom: 2.5rem;
				}
			}

			.room__excerpt {

				p:last-child {
					margin-bottom: 0;
				}
			}

			.room__footer {
				margin-top: 2.1875rem;
			}

		// Room Image
		.room__img {
			margin: 0;
			order: 1;
			position: relative;
			overflow: hidden;

			@include media-breakpoint-up(lg) {
				order: 2;
				width: 58%;
				margin-top: 40px;
				margin-bottom: 40px;
			}

			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				background-color: rgba(0,0,0,.2);
				pointer-events: none;
				opacity: 1;
				transition: opacity 0.4s;
				backface-visibility: hidden;
				z-index: 1;
			}

			img {
				width: 100%;
				height: auto;
				transition: transform 0.4s;
			}

			// Hover
			&:hover {

				img {
					transform: scale(1.1);
				}

				&::before {
					opacity: 0;
				}
			}
		}
}



/*----------  Rooms - Grid  ----------*/

.rooms--grid {

	& + nav {
		padding-top: 2rem;
	}

	.room {
		margin-bottom: 30px;
	}

	// Remove bottom margin from 'non-margins' grid
	&.no-gutters  {

		.room {
			margin-bottom: 0;
		}
	}

		.room__inner {
			position: relative;
			box-shadow: $box-drop-shadow-slight;
			transition: all 0.4s ease-in-out;

			&:hover {
				box-shadow: $box-drop-shadow;

				.room__img img {
					transform: scale(1.1);
				}
			}
		}

			.room__img {
				margin: 0;
				position: relative;
				overflow: hidden;

				&::before {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					top: 0;
					background-color: rgba(0,0,0,.42);
					z-index: 2;
					pointer-events: none;
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					top: 25%;
					left: 0;
					right: 0;
					bottom: 0;
					background-image: linear-gradient(to bottom, transparent, rgba(0,0,0,1));
					z-index: 2;
					pointer-events: none;
				}

				img {
					position: relative;
					z-index: 1;
					transition: transform 0.4s;
					width: 100%;
					height: auto;
				}

				a {
					display: block;
				}
			}

			// Content
			.room__body {
				position: absolute;
				z-index: 3;
				bottom: 1.875rem;
				left: 1.875rem;
				right: 1.875rem;

				> *:last-child {
					margin-bottom: 0;
				}
			}
				.room__title {
					font-size: $h4-font-size;
					margin-bottom: 1em;
				}
}


/**
 *
 * Grid - Spaced
 *
 */

.rooms--grid-space {

	// Content
	.room__body {
		top: 1.875rem;
		pointer-events: none;
	}
		.room__title {
			font-size: $h6-font-size;
			text-transform: uppercase;
			margin-top: 0.25rem;
			padding-right: 80px;
		}
		.room__meta {
			position: absolute;
			bottom: 0;
		}
		.room__complexity {
			position: absolute;
			right: 0;
			top: 0;
		}

		.room__img {

			&::after {
				top: 0;
				background-image: linear-gradient(to bottom, transparent 50%, rgba(0,0,0,1)), linear-gradient(to bottom, rgba(0,0,0,.75), transparent 30%);
			}
		}
}




/*----------  Rooms - Modern  ----------*/
.rooms--modern {

	.room {
		display: flex;
		align-items: center;
		margin-bottom: 5rem;
		flex-wrap: wrap;

		@include media-breakpoint-up(md) {
			flex-wrap: nowrap;
		}
	}

		.room__body {
			order: 2;
			position: relative;
			z-index: 3;

			@include media-breakpoint-up(md) {
				padding: 3rem .5rem 3rem 4rem;
			}

			@include media-breakpoint-only(md) {
				padding: 1rem 0 1rem 1.5rem;
			}
		}
			.room__title {
				font-size: 1.5rem;

				@include media-breakpoint-up(md) {
					font-size: 2.5rem;
					margin-left: -8.125rem;
				}

				@include media-breakpoint-only(md) {
					font-size: 1.5rem;
					margin-left: 0;
				}
			}
			.room__meta {
				margin-bottom: 2.25rem;

				@include media-breakpoint-only(md) {
					margin-bottom: 1rem;

					.room__meta-item {
						margin-right: 0.5rem;

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
			.room__excerpt {

				@include media-breakpoint-up(md) {
					padding-bottom: 1rem;
				}

				@include media-breakpoint-only(md) {
					font-size: .75rem;
				}
			}
			.room__footer {

				@include media-breakpoint-up(md) {
					margin-left: -6.5rem;
				}

				@include media-breakpoint-only(md) {
					margin-left: 0;

					.btn-link--dash {

						&::before {
							width: 40px;
						}
					}
				}
			}

		.room__img {
			order: 1;
			margin: 0 0 1.5rem 0;
			position: relative;
			overflow: hidden;
			box-shadow: $box-drop-shadow;

			@include media-breakpoint-up(md) {
				flex-basis: 57%;
				min-width: 57%;
				margin-bottom: 0;
			}

			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				background-color: rgba(0,0,0,.42);
				z-index: 2;
				transition: opacity 0.4s;
				pointer-events: none;
			}

			img {
				transition: transform 0.4s;
			}

			// Hover
			&:hover {

				img {
					transform: scale(1.1);
				}

				&::before {
					opacity: 0;
				}
			}
		}
}


// With Icon on Hover
.rooms--has-icon {

	.room__img {

		a {

			&::before {
				content: "\f218";
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				width: 72px;
				height: 72px;
				margin: -36px 0 0 -36px;
				border-radius: 50%;
				background-color: theme-color("primary");
				color: theme-color("light");
				z-index: 4;
				pointer-events: none;
				transform: scale(0);
				transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
				opacity: 0;
				font-family: "Ionicons";
				font-style: normal;
				font-weight: normal;
				font-size: 32px;
				line-height: 72px;
				text-align: center;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}

			&:hover {

				&::before {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}
}



/*----------  Rooms - Blocks  ----------*/
.rooms--blocks {
	margin-bottom: 4rem;

	.room {
		width: 100%;
		background-image: url("../../assets/img/samples/room-block-img-1.jpg");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		text-align: center;
		height: 150px;
		position: relative;
		overflow: hidden;

		&::before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: rgba(0,0,0,.5);
			z-index: 1;
			transition: background-color 0.4s;
		}

		@include media-breakpoint-up(md) {
			height: 240px;
		}

		@include media-breakpoint-up(lg) {
			height: 300px;
		}

		&:hover {

			&::before {
				background-color: rgba(0,0,0,.75);
			}
		}
	}
		.room__link-wrapper {
			position: relative;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
		.room__body {

		}

			.room__title {
				font-size: 1.5rem;
				margin-bottom: 0;

				@include media-breakpoint-up(md) {
					font-size: 2.5rem;
				}
			}

			img {
				width: 100%;
				height: auto;

			}

			// Hover
			&:hover {


			}


	// Demo Only
	.room--room-2 {
		background-image: url("../../assets/img/samples/room-block-img-2.jpg");
	}
	.room--room-3 {
		background-image: url("../../assets/img/samples/room-block-img-3.jpg");
	}
	.room--room-4 {
		background-image: url("../../assets/img/samples/room-block-img-4.jpg");
	}
	.room--room-5 {
		background-image: url("../../assets/img/samples/room-block-img-5.jpg");
	}
}


/*=====  End of #Rooms  ======*/
