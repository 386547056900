/**
 *
 * Room badge
 *
 */

// Room badge
.room__badge {
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
	width: 40px;
	height: 40px;
	color: theme-color("light");
	font-size: 18px;
	line-height: 1em;

	&::before {
		content: "";
		display: block;
		position: absolute;
		z-index: -1;
		right: 0;
		top: 0;
		width: 0;
		height: 0;
		border-bottom: 70px solid transparent;
		border-right: 70px solid theme-color("primary");
	}

	[class*='fa-'] {
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

// Colors
.room__badge--secondary {

	&::before {
		border-right-color: theme-color("secondary");
	}
}

// Inverse
.room__badge--inverse {
	right: auto;
	left: 0;

	&::before {
		right: auto;
		left: 0;
		border-right: none;
		border-left: 70px solid theme-color("primary");
	}

	&.room__badge--secondary {

		&::before {
			border-left-color: theme-color("secondary");
		}
	}
}
