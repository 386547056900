/* Preloader */
.preloader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $preloader-bg;
	z-index: 999999;
}

@keyframes pulseScaleOut {
	0% {
		transform: scale(0);
	} 100% {
		transform: scale(1.0);
		opacity: 0;
	}
}

.preloader {
	position: fixed;
	left: 50%;
	top: 50%;
	width: $preloader-size;
	height: $preloader-size;
	margin-left: -$preloader-size / 2;
	margin-top: -$preloader-size / 2;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	border-radius: 100%;
	animation: pulseScaleOut 1.0s infinite ease-in-out;
	background-color: $preloader-color;
}
